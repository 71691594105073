import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import * as _ from 'lodash';
import FormValidator from '../libs/misc/form-validator';
import AVLive from '../libs/misc/mil-av-live';
(function (init) {
  init(window.jQuery, window, document);
})(function ($, window, document) {
  function validateEmail(email) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  function validateFields($fields) {
    var allFilled = true;
    var validEmail = true;
    $.each($fields, function (field, $element) {
      if ($element.val() === '') {
        allFilled = false;
        $element.parent().addClass('errore'); // Optional: Highlight the empty field
      } else {
        $element.parent().removeClass('errore'); // Reset the border if the field is filled
      }
      if (field === 'email' && !validateEmail($element.val())) {
        validEmail = false;
        $element.parent().addClass('errore'); // Add error class if email is not valid
      } else if (field === 'email' && validateEmail($element.val())) {
        $element.parent().removeClass('errore'); // Remove error class if email is valid
      }
    });
    return allFilled && validEmail;
  }
  function init_register_single_page() {
    var $proceedButton = $('#btn-proceed');
    var fields = ['name', 'surname', 'email', 'phone', 'event_id', 'age'];
    var $fields = {};
    $.each(fields, function (index, field) {
      $fields[field] = $('#' + field);
    });
    $proceedButton.click(function (e) {
      e.preventDefault();
      var isValid = validateFields($fields);
      if (isValid) {
        $('#step-1').addClass('disabled');
        $('#step-1 input, #step-1 select, #step-1 .select2').addClass('disabled-input');
        $proceedButton.fadeOut(function () {
          $('#step-2').fadeIn(); // Show step-2
        });
      }
      return false;
    });
    var registerFormValidator = new FormValidator({
      form: {
        id: '#register-single-form',
        errors: '.register-errors',
        btn_submit: '#btn-register',
        recaptcha: '#g-recaptcha-response',
        ajax: true,
        ajaxSuccess: function ajaxSuccess(data, textStatus, jqXHR) {
          console.log('success', data, textStatus, jqXHR);
        },
        ajaxError: function ajaxError(data, textStatus, jqXHR) {
          console.error('error', data, textStatus, jqXHR);
        }
      },
      fields: ['name', 'surname', 'email', 'phone', 'event_id', 'age', 'remember', 'remember2', 'filepond-pdf-input', 'filepond-video-input'],
      init_fields: [{
        name: 'age',
        type: 'select2',
        params: {
          placeholder: '*Età',
          allowClear: false
        }
      }, {
        name: 'event_id',
        type: 'select2',
        params: {
          placeholder: '*Regione',
          allowClear: false,
          matcher: function matcher(params, data) {
            //console.log(params, data)

            // If there are no search terms, return all of the data
            if ($.trim(params.term) === '') {
              return data;
            }

            // Do not display the item if there is no 'text' property
            if (typeof data.text === 'undefined') {
              return null;
            }

            // `params.term` should be the term that is used for searching
            // `data.text` is the text that is displayed for the data object
            if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1 || data.id == -1) {
              var modifiedData = $.extend({}, data, true);
              //modifiedData.text += ' (matched)';

              // You can return modified objects from here
              // This includes matching the `children` how you want in nested data sets
              return modifiedData;
            }

            // Return `null` if the term should not be displayed
            return null;
          },
          ajax: {
            url: '/get-events',
            dataType: 'json',
            data: function data(params) {
              var query = {
                s: params.term
              };
              return query;
            },
            processResults: function processResults(data) {
              return {
                results: data
              };
            }
          },
          templateResult: function templateResult(item) {
            if (item.loading) {
              return item.text;
            }
            var $template = $("<div>\n                                \n                                <b class=\"title\"></b>\n                                &nbsp;\n                                <small class=\"desc small d-block\"></small>\n                            \n                            </div>");

            //$template.find('.title').text(`${item.title} | ${item.date} | ${item.time}`)
            $template.find('.title').text("".concat(item.text));

            //$template.find('.desc').text(`${item.hotel} | ${item.location}`)
            $template.find('.desc').text("".concat(item.desc));
            return $template;
          },
          templateSelection: function templateSelection(item) {
            return item.text;
          }
        }
      }],
      errors: {
        name: 'Inserisci un nome valido',
        surname: 'Inserisci un cognome valido',
        email: 'Inserisci una email valida',
        age: 'Inserisci età valida',
        phone: 'Inserisci un numero di telefono valido',
        event_id: 'Inserisci un evento valido',
        is_password_accettable: 'Scegli una password più sicura',
        remember: 'Accetta l’informativa privacy e il regolamento completo del concorso',
        remember2: 'Accetta la liberatoria',
        recaptcha: 'Clicca su "Non sono un robot"',
        'filepond-pdf-input': 'Completa il caricamento di un file PDF con il testo',
        'filepond-video-input': 'Completa il caricamento di un file MP4 con il video'
        //  'filepond-privacy-input': 'Completa il caricamento della liberatoria in PDF',
      },
      password_mode: false,
      remote_errors: [{
        param: 's',
        value: '409',
        message: 'Utente già registrato'
      }, {
        param: 's',
        value: '422',
        message: 'Password errata'
      }, {
        param: 's',
        value: '400',
        message: 'Errore recaptcha, riprova'
      }]
    });
    var is_register = registerFormValidator.initialize();
  }
  $(function () {
    init_register_single_page();
  });
});